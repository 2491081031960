// 添加/编辑 模板
<template>
  <!-- 资料库>新增资料 -->
    <div class="dicts"  v-loading.fullscreen.lock="loadingDetail">
        <el-page-header
        style="margin-bottom: 1.04167vw"
        @back="goBack"
        :content="'运营模板/'+title"
        >
            <template slot="title">
                <i class="el-icon-arrow-left"></i>返回
            </template>
        </el-page-header>
        <div class="step_wap">
            <div :class="stepNum == 1?'step step1 active':'step step1 ' ">基础设置</div>
            <div :class="stepNum == 2?'step step2 active':'step step2'">项目设置</div>
            <div :class="stepNum == 3?'step step3 active':'step step3'">完成</div>
        </div>
        <el-form ref="ruleForm" v-if="stepNum == 1" style="margin-top:1.5625vw;width: 80%;" :rules="rules" :model="form" :label-width="labelWidth">
            <el-form-item label="模板名称：" prop="templateName">
                <el-input v-model.trim="form.templateName" :maxlength="20" placeholder="请输入模板名称"></el-input>
            </el-form-item>
            <el-form-item label="描述：" prop="templateDesc">
                <el-input type="textarea"  :rows="4" :maxlength="500" v-model="form.templateDesc" placeholder="请输入描述"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="templateSort">
                <el-input v-model="form.templateSort" placeholder="请输入排序" v-input-number  :maxlength="3" @keyup.native="getInputChange(form.templateSort,'templateSort')"></el-input>
            </el-form-item>
            <el-form-item label="是否启用：" prop="isUse">
                <el-radio-group v-model="form.isUse">
                    <el-radio :label="1">启用</el-radio>
                    <el-radio :label="0">不启用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="nextStep(1)">下一步</el-button>
            </el-form-item>
        </el-form>
        <div v-show="stepNum == 2" class="tableBox">
            <div style="margin:1.04167vw 0" class="setLevel">
                <el-radio-group v-model="openLevel" @change="changeOpenLevel">
                <el-radio :label="0">展开一级</el-radio>
                <el-radio :label="2">展开二级</el-radio>
                <el-radio :label="3">展开全部</el-radio>
                </el-radio-group>
            </div>
                <!-- :span-method="arraySpanMethod" -->
                <!--  -->
                <!-- 
                 -->
                <!-- height:calc(100% - 5.52083vw); -->
            <el-table
                :header-cell-style="{
                    background: '#F1F4F9',
                    color: '#5E6D9B',
                    fontWeight: '400',
                    'text-align':'center'
                }" class="tableList"
                :data="tableData"
                v-loading="tableLoading"
                style="width: 100%;"
                ref="tableDatas" 
                :tree-props="{ children: 'itemList', hasChildren: 'hasChildren' }"
                :row-key="row => { return row.id }"
                :expand-row-keys="expandRow"
                @selection-change="handleSelectionChange"
                @select="handleSelect"
                @select-all="selectAll"
                show-summary
                :summary-method="getSummaries"
            >
                <el-table-column type="selection" align="center"  width="55" ></el-table-column>
                <el-table-column  prop="itemName" width="200" label="运营项目" align="left" >
                </el-table-column>
                <el-table-column  prop="predictDay" width="130" label="预计办理时长（天）" align="center" >
                </el-table-column>
                <el-table-column  prop="timeoutUserType" width="90" label="逾期反馈人" align="center" >
                    <template slot-scope="scope">
                    <span v-if="scope.row.timeoutUserType">{{scope.row.timeoutUserType.label}}</span>
                    </template>
                </el-table-column>
                <el-table-column  prop="jobId" label="岗位" align="center" >
                </el-table-column>
                <el-table-column  prop="isMust" label="完成情况" align="center" >
                    <template slot-scope="scope">
                    <span v-if="scope.row.isMust">{{scope.row.isMust.label}}</span>
                    </template>
                </el-table-column>
                <el-table-column  prop="predictDay"  width="200"  align="center" >
                    <template slot="header" >
                        <span style="color:red;">*</span>开始时间
                    </template>
                    <template slot-scope="scope">
                        <el-date-picker :key="scope.row.id"
                            v-if="scope.row.level == 3"
                            style="width:100%;"
                            v-model="scope.row.startTime"
                            type="date"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="scope.row.startTimeProps"
                            @input="getStartDateTime(scope.row)"
                            placeholder="选择日期">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column  prop="endTime"  width="200" label="截至时间" align="center" >
                    <template slot-scope="scope">
                        <el-date-picker
                            v-if="scope.row.level == 3"
                            style="width:100%;"
                            v-model="scope.row.endTime"
                            type="date"
                            :picker-options="scope.row.endTimeProps"
                            @input="getDateTime(scope.row)"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column  prop=""  width="150" label="频次" align="center" >
                    <template slot="header" >
                        <span style="color:red;">*</span>频次
                    </template>
                    <template slot-scope="scope">
                        <!-- {{scope.row.itemTimes}} -->
                        <el-input v-if="scope.row.level == 3" :disabled="!scope.row.endTime" type="number" min="0"  @input="changeTimes(scope.row)" :maxlength="3" placeholder="频次" style="width:90%" v-model="scope.row.itemTimes" />
                    </template>
                </el-table-column>
                <el-table-column  prop=""  width="150" label="周期(天)" align="center" >
                    <template slot-scope="scope">
                        <!-- {{scope.row.endTime }} -->
                        <el-input v-if="scope.row.level == 3" :maxlength="3" type="number" min="1" style="width:90%" v-model="scope.row.period" placeholder="周期(天)" v-input-number @input="uploadEndTime(scope.row)" :disabled="!(!scope.row.endTime)" />
                    </template>
                </el-table-column>
                <el-table-column  prop="updateTime" width="150" label="更新时间" align="center" >
                </el-table-column>
                <!--  fixed="right" -->
                <el-table-column label="操作"  align="center" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.level == 3" @click="handleEdtail(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="stepBtns">
                <el-button @click="nextStep(2)">上一步</el-button>
                <el-button  type="primary" @click="nextStep(3)" :loading="addLoading" :disabled="addLoading">下一步</el-button>
            </div>
        </div>
        <div  v-if="stepNum == 3">
            <div class="completeIcon">
                <img src="@/assets/img/complete.png" alt="">
            </div>
            <p class="completeTitle">模板设置成功！</p>
            <div class="completeIcon">
                <el-button type="primary" @click="goBack">完成</el-button>
            </div>
        </div>
        <el-drawer
        title="运营项目详情"
        :append-to-body="true"
        :destroy-on-close="true"
        :visible.sync="drawerVisible"
        size="50%"
        :before-close="handleDrawerClose">
            <div class="drawerBox">
                <item-detail :detailId="detailId" v-if="drawerVisible"></item-detail>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import itemDetail from '@/views/opratingManage/operationalItems/edit.vue'
export default {
    components:{
        itemDetail
    },
    data(){
        var checkNum = (rule, value, callback) => {
            if (!value) {
                return callback();
            }
            let valueString = value + "";
            let numberAuth = /[^\d]/g;
            if (numberAuth.test(valueString)) {
                callback(new Error("只支持数字"));
            }
            else {
                callback();
            }
        };
        return{
            pickerOptions: {
                disabledDate(time) {
                    let _now = Date.now();
                    return time.getTime() > _now;
                }
            },
            title:'',
            stepNum:1,
            form:{
                templateName:'',
                templateDesc:'',
                templateSort:0,
                isUse:1,
                itemTemplateTempList:[]
            },
            rules:{
                templateName: [
                    { required: true, message: "请输入模板名称", trigger: "blur" },
                ],
                templateDesc: [
                    { required: true, message: "请输入描述", trigger: "blur" },
                ],
                isUse: [
                    { required: true, message: "请选择是否启用", trigger: "blur" },
                ],
                templateSort:[
                    { required: false,},
                    { validator: checkNum, trigger: 'blur' }
                ]
            },
            tableData:[],
            tableLoading:false,
            expandRow:[],
            openLevel:3,
            itemNum:0,
            drawerVisible:false,
            detailId:'',
            tableSelectData:[],
            isAllSelect:false,
            loadingDetail:false,
            metaTitle:'',
            addLoading:false,

            labelWidth: '120px',
        }
    },
    created(){
        if(this.$route.query.isEdit == 0){
            this.title = '新增'
            this.metaTitle = '新增模板'
        }else if(this.$route.query.isEdit == 1){
            this.title = '编辑'
            this.getDetail()
            this.metaTitle = '编辑模板'
        }else if(this.$route.query.isEdit == 2){
            this.title = '新增'
            this.getDetail()
            this.metaTitle = '新增模板'
        }
        let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList") ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList")) : null
        chooseMenuInfoList.map((item) => {
        if(item.path == this.$route.path){
            item.meta.title = this.metaTitle
        }
        })
        let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList)) 
        breadCrumbList.shift()
        // console.log(breadCrumbList)
        this.$store.commit("common/getBreadcrumbList",breadCrumbList || [])
        
        this.init()
    },
    mounted(){
        window.addEventListener("resize",(e) => {
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
            }
            this.$forceUpdate()
        })
        if(window.innerWidth < 1300){
            this.labelWidth = '80px'
        } else {
            this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
        }
    },
    watch:{
        'form.itemTemplateTempList':{
            handler(newVal,oldVal){
                if(newVal){
                    let num = 0
                    for (let index = 0; index < this.form.itemTemplateTempList.length; index++) {
                        let ele = this.form.itemTemplateTempList[index]
                        // && ele.period && ele.startTime && ele.endTime
                        if(ele.child ){
                            // num = this.initDays(ele.startTime,ele.endTime)*ele.itemTimes
                            // if(!ele.startTime){
                            //     ele.startTimeProps = {
                            //         disabledDate(time) {
                            //             let _now = Date.now();
                            //             return time.getTime() < _now;
                            //         }
                            //     }
                            //     ele.endTimeProps = {
                            //         disabledDate(time) {
                            //             let _now = Date.now();
                            //             return time.getTime() < _now;
                            //         }
                            //     }
                            // }
                            console.log(ele)
                            num += parseFloat(ele.predictDay)*ele.itemTimes
                        }
                    }
                    if(isNaN(num)){
                        num = 0
                    }
                    this.itemNum = num
                }
            },
            deep: true
        }
    },
    methods:{
        initDays(data1,data2){
            let dateSpan,iDays
            let sDate1 = Date.parse(data1.replace(/-/g, '/'));
            let sDate2 = Date.parse(data2.replace(/-/g, '/'));
            dateSpan = sDate2 - sDate1;
            dateSpan = Math.abs(dateSpan);
            iDays = Math.floor(dateSpan / (24 * 3600 * 1000))+1;
            console.log(iDays,'天数')
            return iDays
        },
        goBack() {
            this.$router.go(-1);
        },
        nextStep(step){
            if(step == 1){
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                         if(this.form.isCustom){
                            delete this.form.isCustom
                        }
                        let params = this.form
                        if(this.$route.query.isEdit == 0){
                            params.flag = 'add'
                        }else if(this.$route.query.isEdit == 2){
                            params.flag = 'copy'
                        }else{
                            params.flag = 'edit'
                        }
                        // 验证名称是否重复
                        this.addLoading = true
                        this.$api.workTemplateCheckName(this.form).then((res)=>{
                            if(res.data.code == '0'){
                                 this.stepNum = 2
                                setTimeout(()=>{
                                    this.isAllSelect = false
                                    this.$refs.tableDatas.clearSelection()
                                    this.changeOpenLevel(4)
                                    this.initChooseEle()
                                },200)
                            }else{
                                this.$message.error(res.data.msg)
                            }
                            this.addLoading = false
                        }).catch(error=>{
                            this.addLoading = false
                        })
                    }
                })
            }else if(step == 2){
                this.stepNum = 1
            }else if(step == 3){
                // console.log(this.form.itemTemplateTempList.length,this.form.itemTemplateTempList,'length')
                if(this.form.itemTemplateTempList.length == 0){
                    this.$message.error('请选择项目！')
                    return
                }
                console.log(this.form.itemTemplateTempList)
                if(this.form.isCustom){
                    delete this.form.isCustom
                }
                for (let index = 0; index < this.form.itemTemplateTempList.length; index++) {
                    const e = this.form.itemTemplateTempList[index];
                    // console.log(e)
                    if(e.child){
                        if(!e.startTime){
                            this.$message.error('请选择开始时间！')
                            return
                        }
                        if(!e.itemTimes){
                            this.$message.error('请输入频次！')
                            return
                        }
                        if(e.itemTimes && e.itemTimes<=0){
                            this.$message.error('频次必须大于0！')
                            return
                        }
                        console.log(e.period)
                        if(e.period<0){
                            this.$message.error('周期必须大于0！')
                            return
                        }
                        if(!e.period){
                            this.$message.error('请输入周期！')
                            return
                        }
                    }
                }
                this.addLoading = true
                if(this.$route.query.isEdit == 0 || this.$route.query.isEdit == 2){
                    // 添加模板
                    this.$api.workTemplateSave(this.form).then((res)=>{
                        if(res.data.code == '0'){
                            this.$message.success('添加成功')
                            this.stepNum = 3
                            this.addLoading = false
                        }else{
                            this.$message.error(res.data.msg)
                            this.addLoading = false
                        }
                        
                    }).catch(error=>{
                        this.addLoading = false
                    })
                }else if(this.$route.query.isEdit == 1){
                    // 编辑模板
                    this.$api.workTemplateEdit(this.form).then((res)=>{
                        if(res.data.code == '0'){
                            this.$message.success('编辑成功')
                            this.stepNum = 3
                            this.addLoading = false
                        }else{
                            this.$message.error(res.data.msg)
                            this.addLoading = false
                        }
                       
                    }).catch(error=>{
                        this.addLoading = false
                    })
                }

            }
        },
        /** 初始化 */
        init() {
            this.tableLoading = true;
            this.$api.getOperateList(
                Object.assign({
                    itemName: null,
                })
            ).then((res) => {
                this.tableLoading = false;
                if (res.data.code == "0") {
                    this.tableData = res.data.data;
                } else {
                    this.tableData = [];
                    this.$message.error(res.data.msg);
                }
            });
        },
        initChooseEle(){
            if(this.tableData){
                this.tableData.forEach((e,index)=>{
                    e.firstIndex = index
                    e.sendIndex = null
                    e.thirdIndex = null
                    if(e.itemList){
                        e.itemList.forEach((el,i)=>{
                            el.firstIndex = index
                            el.sendIndex = i
                            el.thirdIndex = null
                            if(el.itemList){
                                el.itemList.forEach((ele,j)=>{
                                    ele.firstIndex = index
                                    ele.sendIndex = i
                                    ele.thirdIndex = j
                                    ele.itemTimes = 1
                                    // 编辑/查看
                                    // console.log(this.form.itemTemplateTempList,'this.form.itemTemplateTempList')
                                    if(this.form.itemTemplateTempList && this.$route.query.isEdit >= 1){
                                        // console.log('查看')
                                        this.form.itemTemplateTempList.forEach(item=>{
                                            // && item.startTime
                                            if(item.itemId == ele.id){
                                                ele.startTime = item.startTime
                                                ele.endTime = item.endTime
                                                console.log(ele.startTime,'ele.startTime')
                                                if(ele.startTime){
                                                    ele.endTimeProps = {
                                                        disabledDate(time) {
                                                            let _start =  new Date(ele.startTime.replace(/-/g, '/')).getTime()
                                                            return time.getTime() < _start;
                                                        }
                                                    }
                                                }else{
                                                    ele.endTimeProps = {
                                                        disabledDate(time) {
                                                            let _now = Date.now();
                                                            return time.getTime() < _now;
                                                        }
                                                    }
                                                }
                                                if(ele.endTime){
                                                    ele.startTimeProps = {
                                                        disabledDate(time) {
                                                            let _end =  new Date(ele.endTime.replace(/-/g, '/')).getTime()
                                                            let _now = Date.now();
                                                            return  time.getTime() > _end || _now > time.getTime();
                                                        }
                                                    }
                                                }else{
                                                    ele.startTimeProps = {
                                                        disabledDate(time) {
                                                            let _now = Date.now();
                                                            return time.getTime() < _now;
                                                        }
                                                    }
                                                }
                                                // if(item.itemTimes){
                                                //     ele.itemTimes = item.itemTimes
                                                // }else{
                                                //     ele.itemTimes = 1
                                                // }
                                                ele.itemTimes = item.itemTimes
                                                ele.period = item.period
                                                ele.iDays = 0
                                                ele.child = 1
                                                this.itemNum+=item.period
                                                this.$nextTick(() => {
                                                    this.$refs.tableDatas.toggleRowSelection(ele);
                                                })
                                            }else{
                                            }
                                        })
                                    }else{
                                        // 添加
                                        ele.startTime = null
                                        ele.endTime = null
                                        ele.itemTimes = 1
                                        ele.period = ''
                                        ele.iDays = 0
                                        ele.child = 1
                                        ele.startTimeProps = {
                                            disabledDate(time) {
                                                let _now = Date.now();
                                                return time.getTime() < _now;
                                            }
                                        }
                                        ele.endTimeProps = {
                                            disabledDate(time) {
                                                let _now = Date.now();
                                                return time.getTime() < _now;
                                            }
                                        }
                                    }
                                    // console.log(ele)
                                })
                            }
                        })
                    }
                })
            }
        },
        // 展开层级
        changeOpenLevel(val){
            this.resetTabDate();
            if(val == 4){
                this.expandRow = []
                if(this.tableData){
                this.tableData.forEach(e=>{
                    this.expandRow.push(e.id)
                    this.$refs.tableDatas.toggleRowExpansion(e, true);
                    if(e.itemList){
                    e.itemList.forEach(el=>{
                        this.expandRow.push(el.id)
                        this.$refs.tableDatas.toggleRowExpansion(el, true);
                        if(el.itemList){
                        el.itemList.forEach(ele=>{
                            this.$refs.tableDatas.toggleRowExpansion(ele, true);
                            this.expandRow.push(ele.id)
                        })
                        }
                    })
                    }
                })
                }
            }else if(val == 3){
                this.expandRow = []
                this.tableData.map((item) => {
                this.expandRow.push(item.id);
                this.$refs.tableDatas.toggleRowExpansion(item, true);
                if (item.itemList && item.itemList.length > 0) {
                    item.itemList.map((itemChild) => {
                    this.expandRow.push(itemChild.id);
                    this.$refs.tableDatas.toggleRowExpansion(itemChild, true);
                    });
                }
                });
            }else if(val == 2){
                this.expandRow = []
                this.tableData.map((item) => {
                this.expandRow.push(item.id);
                this.$refs.tableDatas.toggleRowExpansion(item, true)
                });
            }
        },
        //收起展开菜单
        resetTabDate(arr) {
            this.expandRow = [];
            let data = arr || this.tableData;
            data.forEach((item) => {
                this.$refs.tableDatas.toggleRowExpansion(item, false);
                if (
                    Object.prototype.toString.call(item.itemList) == "[object Array]"
                ) {
                    this.resetTabDate(item.itemList);
                }
            });
        },
        //改变输入按键事件
        getInputChange(value,type){
            let resultValue = value.replace(/[^\w\.\/]/ig,'')
            this.form[type] = resultValue
        },
        // 跳转详情
        handleEdtail(row){
            this.drawerVisible = true
            this.detailId = row.id
        },
        handleDrawerClose(done){
            this.detailId = ''
            this.drawerVisible = false
        },
        // 全选/取消全选
        selectAll(val){
            console.log('全选',val)
            this.isAllSelect = !this.isAllSelect
            if(val && this.isAllSelect){
                setTimeout(()=>{
                    val.forEach(e=>{
                        this.$nextTick(() => {
                            this.$refs.tableDatas.toggleRowSelection(e,true)
                        })
                        if(e.itemList){
                            e.itemList.forEach(ele=>{
                                this.$nextTick(() => {
                                    this.$refs.tableDatas.toggleRowSelection(ele,true)
                                })
                                if(ele.itemList){
                                    ele.itemList.forEach(element=>{
                                        this.$nextTick(() => {
                                            this.$refs.tableDatas.toggleRowSelection(element,true)
                                        })
                                    })
                                }
                            })
                        }
                    })
                },200)
            }else{
                this.$nextTick(() => {
                    this.$refs.tableDatas.clearSelection()
                })
            }
        },

        handleSelect(selection,row){
            console.log('dddd',selection,row)
            let list = []
            let delList = []
            let index = selection.findIndex((item) => { return item.id === row.id })
            if(index === -1){//主动取消选中
                console.log(111)
                if(row.level != 3){
                    let infoList = this.getList(row.level,row.itemList)
                    console.log('infoList',infoList)
                    delList = delList.concat(infoList)
                }else if(row.level == 3){
                    delList.push(row)
                }
                if(row.level != 1){
                    let upInfoList = this.getUpList(row.level,row)
                    delList = delList.concat(upInfoList)
                }
                delList.map((item) => {//在已选的选项中删除要点掉的选项
                    let index = selection.findIndex((info) => { return info.id === item.id })
                    if(index != -1){
                        selection.splice(index,1)
                    }
                })
                list = selection //赋值最终已选选项list
            } else {//主动选中
                console.log(22)
                if(selection){
                    selection.map((item,i) => {
                        // infoList 
                        let index = list.findIndex((info) => { return info.id === item.id })
                        if(row.level != 3 && index === -1 && item.itemList){
                            let infoList = this.getList(item.level,item.itemList)
                            if(infoList){
                                infoList.map((result) => {
                                let resultIndex = list.findIndex((info) => { return info.id === result.id })
                                    if(resultIndex == -1){
                                        list.push(result)
                                    }
                                })
                                list.push(item)
                            }
                        }else if(item.level === '3' && index === -1){//对于是最低级并且不存在最终已选选项list中的选项--直接放入需要选中的选项list
                            list.push(item)
                        }
                        if(item.level !== '1'){//对于不是最外级的选项--向上找需要选中的选项
                            let upInfoList = this.getUpChooseList(item.level,item,list)
                            list = upInfoList
                        }
                    })
                }
            }
            console.log(list)
            this.$nextTick(() => {//先清空所有选项
                this.$refs.tableDatas.clearSelection()
            })
            setTimeout(() => {//再对刚才拿到的需要选中的选项进行选中效果添加
                list.map((item) => {
                    this.$refs.tableDatas.toggleRowSelection(item,true)
                })
            },300)
        },
        //处理向下找需要取消/选中的选项的筛选
        getList(level,handleList){
            let list = []
            switch(level){
                case '1':
                    console.log(handleList)
                    if(handleList){
                        handleList.map((firstInfo) => {
                            list.push(firstInfo)
                            if(firstInfo.itemList){
                                firstInfo.itemList.map((secondInfo) => {
                                    list.push(secondInfo)
                                })
                            }
                        })
                    }
                    break;
                case '2':
                    if(handleList){
                        handleList.map((firstInfo) => {
                            list.push(firstInfo)
                        })
                    }
                    break;
                default:
                    break;
            }
            return list
        },
        //处理选中项的上级是否需要选中的方法
        getUpChooseList(level,row,list){
            let firstInfo = {}
            let firstNum = 0
            let secondInfo = {}
            let secondNum = 0
            let thirdInfo = {}
            let thirdNum = 0
            console.log(this.tableData)
            console.log('p[p[',level,row.parentId)
            switch(level){
                case '2':
                    console.log(this.tableData[row.firstIndex].itemList)
                    this.tableData[row.firstIndex].itemList.map((item) => {
                        let index = list.findIndex((info) => { return info.id === item.id })
                        if(index != -1){
                            firstNum ++
                        }
                    })
                    if(firstNum == this.tableData[row.firstIndex].itemList.length){
                        let firstIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].id })
                        if(firstIndex == -1){
                            firstInfo = this.tableData[row.firstIndex]
                            list = list.concat([firstInfo])
                        }
                    }
                    break;
                case '3':
                    this.tableData[row.firstIndex].itemList[row.sendIndex].itemList.map((item) => {
                        let index = list.findIndex((info) => { return info.id === item.id })
                        if(index != -1){
                            secondNum ++
                        }
                    })
                    if(secondNum == this.tableData[row.firstIndex].itemList[row.sendIndex].itemList.length){
                        let secondIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].itemList[row.sendIndex].id })
                        if(secondIndex == -1){
                            secondInfo = this.tableData[row.firstIndex].itemList[row.sendIndex]
                            list = list.concat([secondInfo])
                        }
                    }
                    this.tableData[row.firstIndex].itemList.map((item) => {
                        let index = list.findIndex((info) => { return info.id === item.id })
                        if(index != -1){
                            firstNum ++
                        }
                    })
                    if(firstNum == this.tableData[row.firstIndex].itemList.length){
                        let firstIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].id })
                        if(firstIndex == -1){
                            firstInfo = this.tableData[row.firstIndex]
                            list = list.concat([firstInfo])
                        }
                    }
                    break;
                default:
                break;
            }
            return list
        },
        //处理需要点掉选项向上找需要点掉的上级元素
        getUpList(level,row){
            let list = []
            let firstInfo = {}
            let secondInfo = {}
            let thirdInfo = {}
            console.log(row)
            switch(level){
                case '2':
                    firstInfo = this.tableData[row.firstIndex]
                    list = list.concat([firstInfo])
                break;
                case '3':
                    firstInfo = this.tableData[row.firstIndex]
                    secondInfo = this.tableData[row.firstIndex].itemList[row.sendIndex]
                    list = list.concat([firstInfo]).concat([secondInfo])
                break;
                default:
                break;
            }
            return list
        },
        // 选中列表
        handleSelectionChange(val){
            let arr = []
            // console.log(val)
            if(val){
                val.forEach(e=>{
                    if(e.level == 3){
                        arr.push({
                            itemId:e.id,
                            startTime:e.startTime,
                            endTime:e.endTime,
                            itemTimes:e.itemTimes,
                            period:e.period,
                            child:1,
                            predictDay:e.predictDay,
                            // endTimeProps: {
                            //     disabledDate(time) {
                            //         let _now = Date.now();
                            //         return time.getTime() < _now;
                            //     }
                            // },
                            // startTimeProp: {
                            //     disabledDate(time) {
                            //         let _now = Date.now();
                            //         return time.getTime() < _now;
                            //     }
                            // }
                        })
                        e.startTimeProps = {
                            disabledDate(time) {
                                let _now = Date.now();
                                return time.getTime() < _now;
                            }
                        }
                        e.endTimeProps = {
                            disabledDate(time) {
                                let _now = Date.now();
                                return time.getTime() < _now;
                            }
                        }
                        this.$forceUpdate()
                        let parentIdList = e.treePath.split(',')
                        parentIdList.forEach(pid=>{
                            arr.push({
                                itemId:pid
                            })
                        })
                        arr = this.unique(arr)
                    }
                })
            }
            this.form.itemTemplateTempList = arr
            

        },
        unique(obj){
            // return Array.from(new Set(arr))
            for(var i=0;i<obj.length;i++){
                for(var j = obj.length - 1; j > 0; j--){
                    if(obj[i].itemId ==  obj[j].itemId && i != j){
                        obj.splice(j, 1);
                    }
                }
            }
            return obj
        },
        uploadEndTime(row){
            if(row.period<=0){
                row.period = ''
            }
            this.$forceUpdate()
            if(this.form.itemTemplateTempList.length>0){
                this.form.itemTemplateTempList.forEach(e=>{
                    if(e.itemId == row.id){
                        e.period = row.period
                        // e.itemTimes = val.itemTimes
                    }
                })
            }
        },
        // 修改频次
        changeTimes(val){
            // console.log(this.form.itemTemplateTempList,'this.form.itemTemplateTempList')
            // console.log(val)
            // console.log(this.tableData)
            if(val.itemTimes<=0){
                val.itemTimes = ''
            }
            this.$forceUpdate()
            
            if(val.startTime && val.endTime){
                let iDays = this.initDays(val.startTime,val.endTime)
                val.period = Math.round(iDays/val.itemTimes)
                if(val.period<1){
                    val.period = 1
                }
                if(this.form.itemTemplateTempList.length>0){
                    this.form.itemTemplateTempList.forEach(e=>{
                        if(e.itemId == val.id){
                            e.period = val.period
                            e.itemTimes = val.itemTimes
                        }
                    })
                }
            }
            if(val.iDays){
                // val.period = val.iDays*val.itemTimes
                val.period = Math.round(val.iDays/val.itemTimes)
                if(val.period<1){
                    val.period = 1
                }
                console.log(val.period,'频次')
                if(this.form.itemTemplateTempList.length>0){
                    this.form.itemTemplateTempList.forEach(e=>{
                        if(e.itemId == val.id){
                            e.period = val.period
                            e.itemTimes = val.itemTimes
                        }
                    })
                }
            }
            // else{
            // }
        },
        getStartDateTime(date){
            this.$forceUpdate()
            var dateSpan, iDays;
            if(date.startTime && date.endTime){
                date.iDays = this.initDays(date.startTime,date.endTime)
                date.period = Math.round(date.iDays/date.itemTimes)
                if(date.period<1){
                    date.period = 1
                }
                console.log(date.iDays,'sssss')
            }
            // console.log(date.startTime)
            if(date.startTime){
                date.endTimeProps = {
                    disabledDate(time) {
                        let _start =  new Date(date.startTime.replace(/-/g, '/')).getTime()
                        return time.getTime() < _start;
                    }
                }
            }else{
                date.startTime = ''
                date.endTimeProps = {
                    disabledDate(time) {
                        let _now = Date.now();
                        return time.getTime() < _now;
                    }
                }
                console.log(date.endTimeProps)
            }
            if(date.itemTimes){
                // date.period = date.iDays*date.itemTimes
                if(this.form.itemTemplateTempList.length>0){
                    this.form.itemTemplateTempList.forEach(e=>{
                        if(e.itemId == date.id){
                            e.startTime = date.startTime
                            e.endTime = date.endTime
                            e.period = date.period
                        }
                    })
                }
            }else{
                if(this.form.itemTemplateTempList.length>0){
                    this.form.itemTemplateTempList.forEach(e=>{
                        if(e.itemId == date.id){
                            e.startTime = date.startTime
                            e.endTime = date.endTime
                            e.period = date.period
                        }
                    })
                }
            }
        },
        // 获取间隔天数
        getDateTime(date) {
            var dateSpan, iDays;
            if(date.startTime && date.endTime){
                let sDate1 = Date.parse(date.startTime.replace(/-/g, '/'));
                let sDate2 = Date.parse(date.endTime.replace(/-/g, '/'));
                dateSpan = sDate2 - sDate1;
                dateSpan = Math.abs(dateSpan);
                // date.iDays = Math.floor(dateSpan / (24 * 3600 * 1000)+1);
                date.iDays = this.initDays(date.startTime,date.endTime)
                date.period = Math.round((date.iDays)/date.itemTimes)
                if(date.period<1){
                    date.period = 1
                }
                console.log(date.iDays,'eeee')
            }
                console.log(date)
            if(date.endTime){
                date.startTimeProps = {
                    disabledDate(time) {
                        let _end =  new Date(date.endTime.replace(/-/g, '/')).getTime()
                        let _now = Date.now();
                        // console.log(_end,_now)
                        return time.getTime() > _end || _now > time.getTime();
                    }
                }
                console.log(date.startTimeProps)
            }else{
                date.endTime = ''
                date.startTimeProps = {
                    disabledDate(time) {
                        let _now = Date.now();
                        return time.getTime() < _now;
                    }
                }
                console.log(date.startTimeProps)
            }
            this.$forceUpdate()
            if(date.itemTimes){
                // date.period = date.iDays*date.itemTimes
                if(this.form.itemTemplateTempList.length>0){
                    this.form.itemTemplateTempList.forEach(e=>{
                        if(e.itemId == date.id){
                            e.startTime = date.startTime
                            e.endTime = date.endTime
                            e.period = date.period
                        }
                    })
                }
            }
            if(this.form.itemTemplateTempList.length>0){
                this.form.itemTemplateTempList.forEach(e=>{
                    if(e.itemId == date.id){
                        e.startTime = date.startTime
                        e.endTime = date.endTime
                    }
                })
            }
        },
        // 合并开始时间和结束时间
        arraySpanMethod({ row, column, rowIndex, columnIndex }){
            if (rowIndex !== 0) {
                if(columnIndex == 6){
                    return [1,2];
                }else if (columnIndex === 7){
                    return [0, 0];
                }
            }
        },
        //表尾合计
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if ( index === 1) {
                    sums[index] = "总计时长";
                    return;
                }
                if (index === 2) {
                    sums[index] = this.itemNum;
                    return;
                }
            });
            return sums;
        },
        getDetail(){
            this.loadingDetail = true
            this.$api.workTemplateEditDetail1(this.$route.query.id).then(res=>{
                this.loadingDetail = false
                if(res.data.code == '0'){
                    let obj = {
                        itemTemplateTempList:res.data.data
                    }
                    this.form = Object.assign(this.form,obj)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
            this.$api.workTemplateEditDetail2(this.$route.query.id).then(res=>{
                if(res.data.code == '0'){
                    res.data.data.workTemplate.isUse =  Number(res.data.data.workTemplate.isUse.value)
                    delete res.data.data.workTemplate.itemTemplateTempList
                    this.form = Object.assign(this.form,res.data.data.workTemplate)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
    },
    beforeDestroy(){
        window.removeEventListener("resize",(e) => {
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
            }
            this.$forceUpdate()
        })
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dicts {
    background-color: #ffffff;
    height: calc(100% - 64px);
    overflow: hidden;
    padding: 34px 30px 30px 30px;
    .tableBox{
        height: calc(100% - 88px);
    }
    .tableList {
        height: calc(100% - 120px);
        /deep/.el-table__body-wrapper{
            height: calc(100% - 120px);
            overflow-y: scroll;
        }
    }
    .step_wap{
        width: 100%;
        display: flex;
        .step{
            width: 150px;
            height: 38px;
            line-height: 38px;
            text-indent: 20px;
            text-align: left;
            color: #A8A6B9;
            &.active{
                font-size: 16px;
                color: #FFFFFF;
            }
        }
        .step1{
            background:url('../../../assets/img/firstStepOff.png') no-repeat;
            &.active{
                background:url('../../../assets/img/firstStepOn.png') no-repeat;
                background-size: 100% auto;
            }
            background-size: 100% auto;
        }
        .step2{
            background:url('../../../assets/img/secondStepOff.png') no-repeat;
            text-indent: 30px;
            &.active{
                background:url('../../../assets/img/secondStepOn.png') no-repeat;
                background-size: 100% auto;
            }
            background-size: 100% auto;
        }
        .step3{
            text-indent: 30px;
            background:url('../../../assets/img/thirdStepOff.png') no-repeat;
            &.active{
                background:url('../../../assets/img/thirdStepOn.png') no-repeat;
                background-size: 100% auto;
            }
            background-size: 100% auto;
        }
    }
    .completeIcon{
        width: 100%;
        margin-top: 80px;
        display: flex;
        justify-content: center;
    }
    .completeTitle{
        color: #010334;
        font-size: 24px;
        text-align: center;
        margin-top: 24px;
    }
    .setLevel{
        display: flex;
        justify-content: flex-end;
    }
    .stepBtns{
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }
}
.drawerBox{
    width: calc(100% - 40px);
    height: calc(100% - 100px);
    padding: 0 20px 100px 20px;
    overflow-y: scroll;
}
.dict{
    height: 100% ;
    min-height: 100%;
    // overflow-y: scroll;
    padding: 0;
}
/deep/.el-table__footer-wrapper tbody td, .el-table__header-wrapper tbody td{
    color: red;
    font-weight: bold;
} 
</style>